import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-365628a1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-item" }
const _hoisted_2 = { class: "category-label" }
const _hoisted_3 = { class: "list-item-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseImgCircle = _resolveComponent("BaseImgCircle")!
  const _component_UrgentListItemNickname = _resolveComponent("UrgentListItemNickname")!
  const _component_UrgentListItemTimestamp = _resolveComponent("UrgentListItemTimestamp")!
  const _component_UrgentListItemContent = _resolveComponent("UrgentListItemContent")!
  const _component_UrgentListItemCommentCount = _resolveComponent("UrgentListItemCommentCount")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: '/urgent/' + _ctx.urgent.id
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.urgent.category), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BaseImgCircle, {
            size: "40px",
            src: 
            _ctx.urgent.thumbnail ?? require('@/assets/images/account_default.png')
          
          }, null, 8, ["src"]),
          _createElementVNode("article", null, [
            _createVNode(_component_UrgentListItemNickname, {
              title: _ctx.urgent.nickname
            }, null, 8, ["title"]),
            _createVNode(_component_UrgentListItemTimestamp, {
              tstamp: _ctx.urgent.tstamp
            }, null, 8, ["tstamp"]),
            _createVNode(_component_UrgentListItemContent, {
              content: _ctx.urgent.content
            }, null, 8, ["content"]),
            _createVNode(_component_UrgentListItemCommentCount, {
              count: _ctx.urgent.commentCount
            }, null, 8, ["count"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}