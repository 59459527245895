
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { useCookies } from "vue3-cookies";
import BottomSheet from "@/components/BottomSheet.vue";
import BaseHeader3 from "@/components/BaseHeader3.vue";
import List from "@/components/announcement/List.vue";
import AnnouncementListItem from "@/components/announcement/ListItem.vue";
import UrgentListItem from "@/components/urgent/ListItem.vue";
import TalkroomListItem from "@/components/talkroom/ListItem.vue";
import { Announcement } from "@/components/announcement/index";
import { TalkroomListItemType } from "@/components/talkroom/talkroom";
import { UrgentListItemType } from "@/components/urgent/urgent";
import LoadingIcon from "icons/Loading.vue";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";

export default defineComponent({
  name: "Home",
  components: {
    BaseHeader3,
    List,
    AnnouncementListItem,
    UrgentListItem,
    TalkroomListItem,
    LoadingIcon,
    InfiniteLoading,
    BottomSheet,
  },
  data() {
    const store = useStore();
    return {
      store,
      infiniteId: +new Date(),
      page: 1,
      showBS: false,
      isResended: false,
      postList: [] as Array<{
        content_type: string;
        content: Announcement | TalkroomListItemType | UrgentListItemType;
      }>,
    };
  },
  computed: {
    isLogin: function (): boolean {
      return this.store.getters["member/isLogin"];
    },
    filteredPostList() {
      const _this = this as any;
      return _this.postList.filter((v: any) => !v.content.is_blind);
    },
  },
  methods: {
    infiniteHandler($state: any) {
      this.load()
        .then((res: any) => {
          $state.loaded();
          if (this.postList.length >= res.data.count) {
            $state.complete();
          }
          this.page += 1;
        })
        .catch(() => {
          $state.error();
        });
    },
    load() {
      return this.$axios
        .get("/urgent/mix/post", {
          params: {
            page: this.page,
          },
        })
        .then((result) => {
          let res = result.data.results;
          if (res.length > 0) {
            if (this.page == 1) {
              this.postList.splice(0, this.postList.length);
              this.postList.push(...res);
            } else {
              this.postList.push(...res);
            }
          }
          return result;
        });
    },
    openLink(announcement: Announcement): void {
      if (announcement?.link) {
        this.$pushGAEvent("announcement_view", {
          announcement_category:
            announcement.role === "배우" ? "actor" : "staff",
          announcement_url: announcement.link,
          announcement_name: announcement.title,
        });

        this.$axios
          .get(`/announcement/external/${announcement.id}`)
          .then(() => {
            announcement.is_read = true;
            this.$flutter.callHandler("launchUrl", {
              url: announcement.link,
            });
          });
      }
    },
    sendVerificationEmail() {
      let user = this.store.getters["member/member"];
      this.showBS = false;

      this.$axios
        .post(`/member/validate`, { email: user.email })
        .then((res) => {
          this.isResended = true;
        })
        .catch((res) => {
          this.isResended = true;
        });
    },
    registerDevice() {
      const { cookies } = useCookies();
      const token = cookies.get("fcm");
      const device_type = cookies.get("os");

      if (token && device_type) {
        this.$axios.post("/device/mobile", {
          token,
          device_type,
        });
      }
    },
  },
  beforeMount() {
    this.registerDevice();
  },
  mounted() {
    let user = this.store.getters["member/member"];
    if (user && !user.is_email_auth) {
      if (!this.store.getters["member/isShowEmailAlert"]) {
        this.showBS = true;
        this.store.dispatch("member/updateIsShowEmailAlert", true);
      }
    }
    this.$flutter.callHandler("showGnb");
  },
});
