import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08dd9e16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-category-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", null, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.iconName), { class: "custom-size" }))
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.disabled ? 'disabled' : '')
      }, _toDisplayString(_ctx.description), 3)
    ])
  ]))
}