
import { defineComponent } from "vue";
import { UrgentListItemType } from "./urgent";
import BaseImgCircle from "@/components/BaseImgCircle.vue";
import UrgentListItemNickname from "./ListItemNickname.vue";
import UrgentListItemTimestamp from "./ListItemTimestamp.vue";
import UrgentListItemContent from "./ListItemContent.vue";
import UrgentListItemCommentCount from "./ListItemCommentCount.vue";

export default defineComponent({
  name: "UrgentListItem",
  components: {
    BaseImgCircle,
    UrgentListItemNickname,
    UrgentListItemTimestamp,
    UrgentListItemContent,
    UrgentListItemCommentCount,
  },
  props: {
    urgent: {
      type: Object as () => UrgentListItemType,
      required: true,
    },
  },
});
