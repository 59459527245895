const CategoryIconList: Array<string> = [
  "CalendarCheckOutline",
  "CurrencyKrw",
  "HumanNonBinary",
];

type Announcement = {
  id: number;
  category: string;
  title: string;
  role: string;
  work_date: string;
  pay: string;
  link: string;
  gender?: string;
  is_read?: boolean;
};

type ListAnnouncement = Array<Announcement>;

export { CategoryIconList, Announcement, ListAnnouncement };
