
import { defineComponent } from "vue";
import MessageOutlineIcon from "icons/MessageOutline.vue";

export default defineComponent({
  name: "UrgentListItemCommentCount",
  components: {
    MessageOutlineIcon,
  },
  props: {
    count: {
      type: Number,
      required: true,
    },
  },
});
