import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10677b45"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListCategoryItemLabel = _resolveComponent("ListCategoryItemLabel")!
  const _component_ListCategoryItem = _resolveComponent("ListCategoryItem")!
  const _component_ListCategory = _resolveComponent("ListCategory")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createVNode(_component_ListCategoryItemLabel, {
      class: "item-label",
      labelName: _ctx.categoryLabel
    }, null, 8, ["labelName"]),
    _createElementVNode("article", null, [
      _createElementVNode("h3", {
        class: _normalizeClass(_ctx.isRead ? 'disabled' : '')
      }, _toDisplayString(_ctx.title), 3),
      _createVNode(_component_ListCategory, null, {
        default: _withCtx(() => [
          _createVNode(_component_ListCategoryItem, {
            iconName: 'CalendarCheckOutline',
            description: _ctx.workDate,
            disabled: _ctx.isRead
          }, null, 8, ["description", "disabled"]),
          _createVNode(_component_ListCategoryItem, {
            iconName: 'CurrencyKrw',
            description: _ctx.pay,
            disabled: _ctx.isRead
          }, null, 8, ["description", "disabled"]),
          (_ctx.gender)
            ? (_openBlock(), _createBlock(_component_ListCategoryItem, {
                key: 0,
                iconName: 'HumanNonBinary',
                description: _ctx.gender,
                disabled: _ctx.isRead
              }, null, 8, ["description", "disabled"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}