
import { defineComponent } from "vue";
import ListCategory from "./ListCategory.vue";
import ListCategoryItem from "./ListItemCategory.vue";
import ListCategoryItemLabel from "./ListItemLabelCategory.vue";

export default defineComponent({
  name: "AnnouncementListItem",
  props: {
    title: {
      type: String,
      default: "",
    },
    categoryLabel: {
      type: String,
      default: "",
    },
    workDate: {
      type: String,
      default: "",
    },
    pay: {
      type: String,
      default: "",
    },
    gender: {
      type: String,
      default: "",
    },
    isRead: {
      type: Boolean,
      default: false,
    },
  },
  components: { ListCategory, ListCategoryItem, ListCategoryItemLabel },
});
