
import { defineComponent, defineAsyncComponent } from "vue";
import { CategoryIconList } from ".";

export default defineComponent({
  props: {
    iconName: {
      type: String,
      validator(name: string) {
        return CategoryIconList.includes(name);
      },
      default: "CalendarCheckOutline",
    },
    description: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CalendarCheckOutline: defineAsyncComponent(
      () => import("icons/CalendarCheckOutline.vue")
    ),
    CurrencyKrw: defineAsyncComponent(() => import("icons/CurrencyKrw.vue")),
    HumanNonBinary: defineAsyncComponent(
      () => import("icons/HumanNonBinary.vue")
    ),
  },
});
