import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f85e9e2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = {
  key: 0,
  class: "unread"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BellOutlineIcon = _resolveComponent("BellOutlineIcon")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("span", null, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("button", {
        class: "alert",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickAlert && _ctx.clickAlert(...args)))
      }, [
        _createVNode(_component_BellOutlineIcon, { size: 25 }),
        (_ctx.isLogin && _ctx.unreadCount > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("span", null, _toDisplayString(_ctx.unreadCount), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}